<template>
  <div class="lead-baner mx-auto">
    <div class="bg">
      <div class="image offer-height" :style="bgImage">
        <slot />
      </div>
    </div>
  </div>
</template>

<!-- eslint-disable global-require -->
<!-- eslint-disable import/no-dynamic-require -->
<script>

export default {
  name: 'LeadBaner',
  computed: {
    bgImage() {
      return {
        backgroundImage: `url(${require('@/assets/evaluation_banner.jpg')})`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/import';

.lead-baner {
  position: relative;
  max-width: 1441px;
  min-height: 350px;
  margin: auto;

  .bg {
    position: absolute;
    width: 100%;
    min-height: 140px;

    &::after {
      max-height: 272px;

      @include md-only() {
        max-height: 252px;
      }

      @include xs-only() {
        max-height: 336px;
      }
    }
  }

  .image {

    z-index: 0;

    position: absolute;
    width: 100%;

    @include md-only() {
      min-height: 176px;
    }

    @include xs-only() {
      min-height: 156px;
    }
  }

  .offer-height {
    min-height: 520px;
  }

  .default-height {
    min-height: 272px;

    @include md-only() {
      min-height: 176px;
    }

    @include xs-only() {
      min-height: 156px;
    }
  }
}
</style>